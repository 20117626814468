import { Value } from "../client";
import { EntityColumn } from "./EntityColumn";
import { EntityLookup, ViewMapping } from "./EntityLookup";


export function isInstanceOfEntityColumn<T extends Value>(obj: any): obj is EntityColumn<T> {
    return obj instanceof EntityColumn;
}

export function isViewMapping(obj: any): obj is ViewMapping {
    return obj && typeof obj.keyIndex === "number" && typeof obj.descriptionIndex === "number";
}

export function isEntityLookup(obj: any): obj is EntityLookup {
    // Basic checks for required properties
    const hasBasicProps = obj && typeof obj.name === "string" && typeof obj.entityConstructor === "function";

    // Optional properties
    const hasView = !obj.view || typeof obj.view === "string";
    const hasProc = !obj.proc || typeof obj.proc === "string";
    const hasBindingColumnKey = !obj.bindingColumnKey || typeof obj.bindingColumnKey === "string";
    const hasViewMapping = !obj.viewMapping || isViewMapping(obj.viewMapping);

    return hasBasicProps && hasView && hasProc && hasBindingColumnKey && hasViewMapping;
}
