import { Value } from "../client";
import { EntityColumn } from "./EntityColumn";
import { EntityColumnFlags } from "./EntityColumn.types";

export function checkAdmittedValue(col: EntityColumn<Value>, value: Value, throwException: boolean = false): boolean {
    let ret: boolean = false;
    if (['char', 'nchar', 'varchar', 'nvarchar', 'text', 'ntext'].includes(col.type)) {
        if (typeof value !== "string" || (!col.hasFlag(EntityColumnFlags.nullable) && value === null))
            ret = false;
        else
            ret = true;
    }
    if (['int', 'bigint', 'float', 'decimal', 'real', 'money'].includes(col.type)) {
        if (typeof value !== "number" || (!col.hasFlag(EntityColumnFlags.nullable) && value === null))
            ret = false;
        else
            ret = true;
    }
    if (['datetime', 'date'].includes(col.type)) {
        if (typeof value !== typeof Date || (!col.hasFlag(EntityColumnFlags.nullable) && value === null))
            ret = false;
        else
            ret = true;
    }
    if (col.type === "bit") {
        if (typeof value !== typeof Boolean || (!col.hasFlag(EntityColumnFlags.nullable) && value === null))
            ret = false;
        else
            ret = true;
    }
    if (throwException) throw new Error(`Invalid value for ${col.name}. Column type is ${col.type} and nullable is ${col.hasFlag(EntityColumnFlags.nullable)} and value is ${value}`);
    return ret;
}

export function hasValue(value: Value) {
    return value !== '' && value !== null && typeof value !== 'undefined';
}

