/* eslint-disable @typescript-eslint/no-explicit-any */

// MicroMconfig is defined in index.HTML

const origin = window.location.origin;
const isLocalhost = origin.startsWith('http://localhost') || origin.startsWith('https://localhost');

const config = isLocalhost ? (window as any).MicroMconfig.localhost : (window as any).MicroMconfig.production;

export const API_URL = `${config.ORIGIN}${config.API_PREFIX}`;
export const APP_ID = config.APP_ID;
