import { useMantineColorScheme } from "@mantine/core";
import { useEffect, useId, useMemo, useRef } from "react";
import { DefaultGridColumnProps, DefaultGridProps, GridColumn, GridImperative, GridOptions } from "../Grid";
import { w2column, w2columnAutoResizeEvent, w2event, w2grid, w2record } from "./W2Grid";
import { useW2ColumnRender } from "./useW2ColumnRender";

export function useGrid({
    columns, rows, selectionMode, onDoubleClick, preserveSelection, onSelectionChanged, showSelectCheckbox,
    rowHeight, stripped, highlightOnHover, columnBorders, rowBorders, withBorder, autoSizeColumnsOnLoad, autoSelectFirstRow,
    columnsOverrides, selectedRows, setSelectedRows
}: GridOptions) {
    const gridRef = useRef<w2grid>(undefined!);
    const boxRef = useRef<HTMLDivElement>(null); //TODO: ver como hacemos para soportar los diferentes tipos de elementos
    const gridName = useId().replace(/:/g, '_'); //fix conflicto con el símbolo ":" en CSS
    const preservedSelection = useRef<number[] | null>(null);
    const { colorScheme } = useMantineColorScheme();
    const columnAutoResizeSource = useRef<"user" | "system">("user"); //flag para determinar si el autoResize disparado en la columna es por una acción del usuario o del sistema, ya que w2ui no lo ha contemplado.

    const {
        w2columnRender, cellsPortals, clearCellPortals, isFirstVisible, performAutosize
    } = useW2ColumnRender({ columns, rows, columnsOverrides, columnAutoResizeSource, gridRef, autoSizeColumnsOnLoad, boxRef });

    //if (isFirstVisible) console.log('grid is visible');

    useEffect(() => {
        //console.log("useGrid effect, gridName, new ref", gridName, showSelectCheckbox);
        gridRef.current = new w2grid({
            name: gridName,
            box: boxRef.current,
            reorderColumns: true,
            show: {
                columnMenu: false,
                //lo que va a continuación se setea solo de forma inicial, los cambios luego se manejan con useEffect, esto es para evitar llamadas innecesarias a refresh().
                selectColumn: showSelectCheckbox,
            },
            onDelete: function (event: w2event) {
                event.preventDefault();
            },
            onRefresh: function () {
                //console.debug("Grid refresh.");
            },
            onColumnAutoResize: async function (event: w2columnAutoResizeEvent) {
                const source = columnAutoResizeSource.current; //guardamos porque luego de await se pierde!

                //console.debug("onColumnAutoResize", source, event.detail);

                //al hacer autosize, se tiene en cuenta la preferencia del usuario si este ha establecido manualmente un tamaño para la columna, en ese caso la columna no puede hacerse mas chica pero puede crecer (conservando la preferencia pero actualizándola al nuevo tamaño para no complicar la lógica)
                if (source === "system" && event.detail.column._microm_userSize_cache && Math.min(Math.abs(event.detail.maxWidth), event.detail.column._microm_autoSizeMax || Infinity, event.detail.column.max || Infinity) < parseInt(event.detail.column.size!)) {  //!: a esta altura siempre tendrá size
                    event.preventDefault();
                    return;
                }

                //simulamos un límite máximo de autoSize ya que w2ui no lo contempla
                const w2max_original = event.detail.column.max;
                if (event.detail.column._microm_autoSizeMax) {
                    // nos fijamos is es la última columna y en ese caso tratamos de llenar el último espacio
                    const columns = gridRef.current.columns;
                    if (columns[columns.length - 1].field === event.detail.column.field) {
                        if (boxRef.current && boxRef.current.offsetWidth > 0) {
                            let columnsWidth: number | undefined = undefined;

                            // tomamos el contenedor de las filas para poder calcular el ancho de la última columna, puede tener scroll vertical
                            const container = boxRef.current.querySelector('.w2ui-grid-records') as HTMLDivElement | null;

                            if (container !== null) {
                                // tomamos el ancho de todas las columnas excepto la última
                                columnsWidth = container.clientWidth - columns.slice(0, -1).reduce((acc, col) => acc + (col.sizeCalculated ? parseInt(col.sizeCalculated!.replace(/\D/g, '')) : 0), 0);
                                columnsWidth = (event.detail.column.min && columnsWidth < event.detail.column.min) ? event.detail.column.min : columnsWidth;

                                // acá restamos 1 px ya que sizeCalculated está redondeado para arriba
                                event.detail.column.max = (columnsWidth && columnsWidth > 0) ? columnsWidth - (columns.length - 1) : undefined;
                            }
                        }
                        else {
                            event.detail.column.max = undefined;
                        }
                    }
                    else {
                        event.detail.column.max = event.detail.column._microm_autoSizeMax;
                    }
                }

                await event.complete;

                event.detail.column._microm_autoSize_cache = true;
                if (source === "user") event.detail.column._microm_userSize_cache = true;

                event.detail.column.max = w2max_original;
            },
            onColumnResize: async function (event: w2event) {
                await event.complete;
                //console.debug("column resize.", event.detail);
                gridRef.current.columns[(event.detail.column as number)]._microm_autoSize_cache = false;
                gridRef.current.columns[(event.detail.column as number)]._microm_userSize_cache = true;
            },
            onResize: async function (event: w2event) {
                await event.complete;
                setTimeout(() => gridRef.current.resizeRecords()); //workaround al problema de usar la grilla dentro de componentes "Tabs", en algunos casos no pinta las filas vacías (con 2 columnas aparece el problema, con 3 o mas no)
            }
            //lo que va a continuación se setea solo de forma inicial, los cambios luego se manejan con useEffect, esto es para evitar llamadas innecesarias a refresh().
            //recordHeight: rowHeight, //NO se puede hacer esto ya que necesitamos que w2ui inicialice con su valor por defecto para que lo guardemos
        });

        if (!DefaultGridProps.rowHeight) DefaultGridProps.rowHeight = gridRef.current.recordHeight; //obtenemos el default de w2ui (solo si no se ha especificado un default)

        return () => {
            gridRef.current.destroy();
        }
    }, [gridName]); //no agregar lo que se setea solo inicialmente (actualizar en effects separados)


    useEffect(() => {
        gridRef.current.onDblClick = async (event: w2event) => {
            await event.complete;
            if (onDoubleClick) onDoubleClick(gridRef.current.getSelection()[0]);
        };
    }, [onDoubleClick]);

    // Managed selection state
    const selectionOrderRef = useRef<Map<number, number>>(new Map());
    const isInternalUpdate = useRef(false);

    // To keep selection order, w2ui makes an itnernal sort and breaks it
    useEffect(() => {
        selectionOrderRef.current.clear();

        selectedRows?.forEach((row, index) => {
            selectionOrderRef.current.set(row.recid, index);
        });
    }, [selectedRows]);

    useEffect(() => {
        gridRef.current.onSelect = async (event: w2event) => {
            await event.complete;
            if (onSelectionChanged || setSelectedRows) {
                const gridSelection = gridRef.current.getSelection(true);
                const unorderedSelection = gridSelection.map(selectedIndex => gridRef.current.records[selectedIndex]);

                // keep order
                const orderedSelection = unorderedSelection.sort((a, b) =>
                    (selectionOrderRef.current.get(a.recid) || 0) - (selectionOrderRef.current.get(b.recid) || 0)
                );

                if (setSelectedRows) {
                    isInternalUpdate.current = true;
                    setSelectedRows(orderedSelection);
                }
                if (onSelectionChanged) onSelectionChanged(orderedSelection);
            }
        };
    }, [onSelectionChanged, setSelectedRows]);

    useEffect(() => {
        if (!isInternalUpdate.current) {
            if (selectedRows && selectedRows.length > 0) {
                gridRef.current.selectNone();

                const selectedRecids = selectedRows.map(selectedRow => selectedRow.recid);
                gridRef.current.select(selectedRecids);
            }
            else {
                gridRef.current.selectNone();
            }
        }
        else {
            isInternalUpdate.current = false;
        }

    }, [selectedRows]);
    //

    useEffect(() => {
        switch (selectionMode!) { //tiene default
            case "multi":
                gridRef.current.multiSelect = true;
                break;
            case "single":
                if (gridRef.current.multiSelect !== false) {
                    gridRef.current.multiSelect = false;

                    const sel = gridRef.current.getSelection();
                    if (sel.length > 1) {
                        gridRef.current.select(sel[0]);
                    }
                }
                break;
        }
    }, [selectionMode]);

    useEffect(() => {
        if (gridRef.current.show.selectColumn !== showSelectCheckbox) {
            gridRef.current.show.selectColumn = showSelectCheckbox!; //tiene default
            gridRef.current.refresh();
        }
    }, [showSelectCheckbox]);

    useEffect(() => {
        if (rowHeight === undefined) return;
        //console.log("useGrid effect, rowHeight", rowHeight);
        if (gridRef.current.recordHeight != rowHeight) {
            gridRef.current.recordHeight = rowHeight; //tiene default
            gridRef.current.refresh();
        }
    }, [rowHeight]);

    // MMC: save selection in preservedSelection
    useEffect(() => {
        //console.log("useGrid effect, preserveSelection", preserveSelection);
        if (preserveSelection) {
            if (gridRef.current.records.length) {
                preservedSelection.current = gridRef.current.getSelection();
            }
        } else {
            preservedSelection.current = null;
        }
    }, [rows, preserveSelection]);

    // MMC: columns
    useEffect(() => {
        //console.log("useGrid effect, columns, columnsOverrides", columns, columnsOverrides);
        //la idea de esto es hacer un merge eficiente al haber cambios en columnas, ya que era ineficiente quitar y recrear las columnas por cada cambio

        if (!columns?.length) {
            if (gridRef.current.columns.length) {
                gridRef.current.removeColumn(...gridRef.current.columns.map(c => c.field)); //llama a refresh()
            }
            return;
        }

        const w2colsUpdated = [];
        const w2colsAdded = [];
        const w2colsRemoved = [];

        for (let colIndex = 0; colIndex < columns.length; colIndex++) {
            const column = columns[colIndex];
            let w2column = gridRef.current.getColumn(column.field);

            if (w2column) {
                let updated = false;
                if (applyW2ColumnProps(w2column, DefaultGridColumnProps, column, columnsOverrides?.[column.field])) {
                    updated = true;
                    w2column._microm_autoSize_cache = false;
                }
                w2column.render = w2columnRender;
                if (updated) {
                    w2colsUpdated.push(w2column);
                }
            } else {
                w2column = {
                    sortable: true,
                    autoResize: true,
                    size: "0", //requerido por el autosize
                    render: w2columnRender,
                    //workaround al problema de autosize de columnas, por alguna razón no afecta las ultimas columnas en algunos casos y quedan del tamaño mínimo, con esto al menos se ven un poco mejor.
                    min: 5 * parseFloat(getComputedStyle(document.documentElement).fontSize), //rem to px
                };
                applyW2ColumnProps(w2column, DefaultGridColumnProps, column, columnsOverrides?.[column.field]);
                w2colsAdded.push({
                    before: Math.min(colIndex + 1, columns.length), //tener en cuenta que las columnas en w2grid pudieron haber sido reordenadas, insertamos las nuevas columnas en el indice donde estarían sin tener en cuenta las que se reordenaron
                    w2column: w2column
                });
            }
        }
        for (let w2colIndex = 0; w2colIndex < gridRef.current.columns.length; w2colIndex++) {
            const w2column = gridRef.current.columns[w2colIndex];
            const column = columns.find(c => c.field === w2column.field);
            if (!column) {
                w2colsRemoved.push(w2column);
            }
        }


        //evitar refresh hasta hacer todo el merge
        const _w2refresh = gridRef.current.refresh;
        gridRef.current.refresh = () => undefined;

        try {

            if (w2colsRemoved.length) {
                gridRef.current.removeColumn(...w2colsRemoved.map(c => c.field)); //llama a refresh()
            }

            if (w2colsAdded.length) {
                for (let i = 0; i < w2colsAdded.length; i++) {
                    const w2colToAdd = w2colsAdded[i];
                    gridRef.current.addColumn(w2colToAdd.w2column, w2colToAdd.before); //no asignar a .columns directamente! ya que eso saltea lógica importante. llama a refresh
                }
            }

        } finally {
            gridRef.current.refresh = _w2refresh;
        }

        gridRef.current.refresh();
        //console.debug("useGrid effect columns updated, added, removed", w2colsUpdated.length, w2colsAdded.length, w2colsRemoved.length);

    }, [columns, columnsOverrides]); // MMC: intencionalmente queda fuera la dependencia de la función render

    //la idea de esto es aplicar valores a las propiedades de una columna de w2ui haciendo merge de GridColumn y overrides: w2column(muta) <- column <- columnOverrides
    function applyW2ColumnProps(w2column: w2column, ...columns: (Partial<GridColumn> | undefined)[]): boolean {
        let effected = false;
        function _apply(w2prop: keyof w2column, prop: keyof GridColumn) {
            const decidedValueSource = columns.findLast(c => c && Object.prototype.hasOwnProperty.call(c, prop));
            if (decidedValueSource) {
                if (w2column[w2prop] !== decidedValueSource[prop]) {
                    (w2column as Record<string, unknown>/*para ignorar warn readonly*/)[w2prop] = decidedValueSource[prop];
                    effected = true;
                }
            }
        }

        _apply("field", "field");
        _apply("text", "text");
        _apply("hidden", "hidden"); //en caso de undefined, se usa default de w2ui

        //estas propiedades no existen en w2ui, pero las usamos nosotros para detección de cambios
        _apply("_microm_format", "format");
        _apply("_microm_render", "render");
        _apply("_microm_autoSizeMax", "autoSizeMax");

        return effected;
    }

    // MMC: rows
    useEffect(() => {

        if (!rows?.length) {
            gridRef.current.clear(); //solo afecta records, llama a refresh
            return;
        }

        gridRef.current.clear(true); //solo afecta records

        // MMC: Es necesario borrar el cache a pesar que el autosize de la grilla es muy lento
        // el autosize de las columnas si la grilla no está visible no funciona, es necesario borrar el cache si hago refresh a mano
        // TODO: cambiar el autosize de la grilla por uno propio que tenga mejor performance, tener en cuenta actualizar el autosize 
        // cuando se agregan registros en una grilla con filas vacias visibles

        for (let w2colIndex = 0; w2colIndex < gridRef.current.columns.length; w2colIndex++) {
            gridRef.current.columns[w2colIndex]._microm_autoSize_cache = false;
        }

        const w2colFields = gridRef.current.columns
            .filter((w2col: w2column) => w2col.field)
            .map((w2col: w2column) => w2col.field!);

        const w2records = rows.map((row, index) => {
            const w2record: w2record = {
                recid: index + 1 //w2ui tiene problemas cuando recid es 0
            };
            for (let i = 0; i < w2colFields.length; i++) {
                w2record[w2colFields[i]] = (row as Record<string, unknown>)[w2colFields[i]];
            }
            return w2record;
        });

        gridRef.current.add(w2records); //llama a refresh
        //console.debug("useGrid effect records added rows.length", rows.length);

    }, [rows]);

    // MMC: clear cell portals when the rows change
    useEffect(() => {
        //console.debug("useGrid effect clear portals, emptyCellsPortals");
        clearCellPortals(); //esto quita todos los portales cuando se actualicen los datos, y luego los renders de las celdas irán creando nuevos portales a medida que se van necesitando
    }, [clearCellPortals, rows]);


    // MMC: workaround for tabs or when the grid is not visible
    useEffect(() => {
        if (isFirstVisible && autoSizeColumnsOnLoad && rows?.length) {
            //console.debug("useGrid effect, autoSizeColumnsOnLoad, isFirstVisible, rows.length, records.length", autoSizeColumnsOnLoad, isFirstVisible, rows?.length, gridRef.current.records.length);

            performAutosize();

        }
        else {
            //console.debug("useGrid effect MISSED, autoSizeColumnsOnLoad, isFirstVisible, rows.length, records.length", autoSizeColumnsOnLoad, isFirstVisible, rows?.length, gridRef?.current?.records.length);
        }
    }, [rows, autoSizeColumnsOnLoad, isFirstVisible, performAutosize]);

    // MMC: set preserved selection
    useEffect(() => {

        if (rows?.length && preservedSelection.current?.length) {
            //console.debug("useGrid effect rows.length, preservedSeletion", rows?.length, preservedSelection.current?.length);
            gridRef.current.select(preservedSelection.current);
            preservedSelection.current = null;
            gridRef.current.scrollIntoView();
        }

    }, [rows]);

    // MMC: autoSelectFirstRow
    useEffect(() => {

        if (autoSelectFirstRow && rows?.length && !gridRef.current.getSelection().length) {
            gridRef.current.select(1);
        }

    }, [columns, rows, autoSelectFirstRow]);

    // MMC: change theme
    useEffect(() => {
        if (boxRef.current) {
            boxRef.current.classList.remove(colorScheme === "dark" ? "w2grid-light" : "w2grid-dark")
            boxRef.current.classList.add(colorScheme === "dark" ? "w2grid-dark" : "w2grid-light")
        }
    }, [colorScheme]);

    useEffect(() => {
        if (boxRef.current) {
            if (stripped) {
                boxRef.current.classList.add("w2grid-stripped")
            } else {
                boxRef.current.classList.remove("w2grid-stripped")
            }
        }
    }, [stripped]);

    useEffect(() => {
        if (boxRef.current) {
            if (highlightOnHover) {
                boxRef.current.classList.add("w2grid-highlight-hover")
            } else {
                boxRef.current.classList.remove("w2grid-highlight-hover")
            }
        }
    }, [highlightOnHover]);

    useEffect(() => {
        if (boxRef.current) {
            if (columnBorders) {
                boxRef.current.classList.add("w2grid-column-borders")
            } else {
                boxRef.current.classList.remove("w2grid-column-borders")
            }
        }
    }, [columnBorders]);

    useEffect(() => {
        if (boxRef.current) {
            if (rowBorders) {
                boxRef.current.classList.add("w2grid-row-borders")
            } else {
                boxRef.current.classList.remove("w2grid-row-borders")
            }
        }
    }, [rowBorders]);

    useEffect(() => {
        if (boxRef.current) {
            if (withBorder) {
                boxRef.current.classList.add("w2grid-border")
            } else {
                boxRef.current.classList.remove("w2grid-border")
            }
        }
    }, [withBorder]);

    return {
        w2grid: gridRef.current,
        boxRef,
        cellsPortals: Object.values(cellsPortals),
        imperative: useMemo<GridImperative>(() => ({
        }), [])
    };
}
