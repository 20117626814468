import { Alert, AlertProps, useComponentDefaultProps } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

export const AlertErrorDefaultProps: Partial<AlertProps> = {
    title: "Error",
    color: "red"
}

export function AlertError(props: AlertProps) {
    const { title, color } = useComponentDefaultProps('AlertError', AlertErrorDefaultProps, props);
    return (
        <Alert icon={<IconAlertCircle size="2rem" />} title={title} color={color} {...props}>
            {props.children}
    </Alert>
    );
}