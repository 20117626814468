import { useEffect, useState } from 'react';
import { Progress, ProgressProps } from '@mantine/core';

export function FakeProgressBar(props: ProgressProps) {
    const [value, setValue] = useState(0);
    const [delay, setDelay] = useState(10);

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((v) => {
                if (v >= 60) {
                    setDelay((d) => d * 2.20);
                    return Math.min(v + 0.01, 100);
                }
                return Math.min(v + 1, 100);
            });
        }, delay);
        return () => clearInterval(interval);
    }, []);

    return <Progress value={value} {...props} />;
};

