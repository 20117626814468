import { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { CommonValidators } from "./CommonValidators";

export type ValidationRule = (value: unknown, values?: Record<string, unknown>) => string | number | true | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | null;
export type ValidatorFunction = (...args: any[]) => ValidationRule;

export type ValidatorConfiguration = Partial<Record<keyof typeof CommonValidators, ValidatorConfigurationParms>>;

export type ValidatorConfigurationParms = {
    message?: ReactNode;
    data?: any;
};

