import { Button, Group, Loader, Space, Stack, Text, useComponentDefaultProps } from "@mantine/core";
import { ForwardedRef, forwardRef } from "react";
import { AlertError } from "../Core";
import { DataGridToolbar } from "../DataGrid";
import { DataGridActionsToolbar } from "../DataGrid/DataGridActionsToolbar";
import { DataViewLabels, DataViewProps } from "./DataView.types";
import { DataViewCardContainer } from "./DataViewCardContainer";
import { useDataView } from "./useDataView";


export const DataViewDefaultProps: Partial<DataViewProps> = {
    search: [],
    limit: "1000",
    toolbarIconVariant: "light",
    actionsButtonVariant: "light",
    modalFormSize: "lg",
    enableAdd: true,
    enableEdit: true,
    enableDelete: true,
    enableExport: true,
    CardContainer: DataViewCardContainer,
    itemsPerPage: 50,
    labels: {
        addLabel: "Add",
        editLabel: "Edit",
        deleteLabel: "Delete",
        viewLabel: "View",
        rowsReturnedLabel: "Rows returned:",
        selectedRowsLabel: "rows selected.",
        limitLabel: "Limit",
        rowsLabel: "rows",
        unlimitedLabel: "Unlimited",
        recordsLabel: "records",
        recordLabel: "record",
        AreYouSureLabel: "Are you sure?",
        YouWillDeleteLabel: "You will delete",
        warningLabel: "Warning",
        YouMustSelectOneOrMoreRecordsToDelete: "You must select one or more records to delete",
        closeLabel: "Close",
        YouMustSelectOneOrMoreRecordsToExecuteAction: "You must select one or more records to execute this action",
        resultLimitLabel: "Results are limited to %s records.",
        loadMoreLabel: "Load More",
        fetchingDataLabel: "Fetching data...",
        noRecordsFoundLabel: "No records found",
        YouMustSelectAtLeast: "You must select at least",
        YouMustSelectBetween: "You must select between",
        YouMustSelect: "You must select",
        YouMustSelectMaximum: "You must select a maximum of",
    },
    filtersFormSize: "md",
    convertResultToLocaleString: true
}

export const DataView = forwardRef(function DataView(props: DataViewProps, ref: ForwardedRef<HTMLElement> | undefined) {
    props = useComponentDefaultProps('DataView', DataViewDefaultProps, props);
    const {
        entity, autoFocus, toolbarIconVariant,
        actionsButtonVariant, enableAdd, enableEdit, enableDelete, enableView, enableExport, labels,
        Card, CardContainer, limit, search, viewName, filtersFormSize
    } = props;

    const dataViewAPI = useDataView(props);
    const { handleLoadMore } = dataViewAPI;

    const limit_number = parseInt(limit || '0');

    return (
        <section ref={ref}>
            <Stack spacing="sm">
                <DataGridToolbar
                    {...labels!}

                    enableExport={enableExport}
                    onExportClick={dataViewAPI.handleExport}

                    onRefreshClick={dataViewAPI.handleRefresh}
                    onCheckboxToggle={dataViewAPI.handleToggleSelectable}
                    autoFocus={autoFocus}
                    toolbarIconVariant={toolbarIconVariant}

                    searchText={dataViewAPI.searchText}
                    setSearchText={dataViewAPI.setSearchText}
                    searchData={dataViewAPI.searchData}
                    setSearchData={dataViewAPI.setSearchData}

                    client={entity?.API.client}

                    FiltersEntity={(entity && viewName) ? entity?.def.views[viewName].FiltersEntity : undefined}

                    filterValues={dataViewAPI.filterValues}
                    setFilterValues={dataViewAPI.setFilterValues}

                    filtersFormSize={filtersFormSize!}
                />
                <Space h="xs" />
                <DataGridActionsToolbar
                    {...labels!}

                    viewName={viewName}

                    onAddClick={dataViewAPI.handleAddClick}
                    onDeleteClick={dataViewAPI.handleDeleteClick}

                    enableAdd={enableAdd}
                    enableEdit={false}
                    enableDelete={enableDelete}
                    enableView={false}

                    actionsButtonVariant={actionsButtonVariant}
                    clientActions={entity ? entity.def.clientActions : {}}
                    handleExecuteAction={dataViewAPI.handleExecuteAction}

                />
                {dataViewAPI.isLoading &&
                    <Group>
                        <Loader size="sm" />
                        <Text fz="sm" c="dimmed">{labels?.fetchingDataLabel}</Text>
                    </Group>
                }
                {!dataViewAPI.isLoading && dataViewAPI.executeViewState.error &&
                    <AlertError mb="xs">Error: {dataViewAPI.executeViewState.error.status} {dataViewAPI.executeViewState.error.message} {dataViewAPI.executeViewState.error.statusMessage}</AlertError>
                }
                {!dataViewAPI.isLoading && !dataViewAPI.executeViewState.error && dataViewAPI.data.length > 0 && dataViewAPI.recordsCount === limit_number && limit_number !== 0 &&
                    <Text fz="sm" align="center" fw={500} c="dimmed">{labels?.resultLimitLabel?.replace('%s', limit || '')}</Text>
                }
                {!dataViewAPI.isLoading && !dataViewAPI.executeViewState.error && dataViewAPI.data.length === 0 && search &&
                    <Text fz="sm" align="center" fw={500} c="dimmed">{labels?.noRecordsFoundLabel}</Text>
                }
                {dataViewAPI.data.length > 0 &&
                    <Group>
                        {
                            CardContainer && entity && dataViewAPI.data.map((record, index) => {
                                return <CardContainer key={index} record={record}
                                    EntityCard={Card}
                                    recordIndex={index}
                                    entity={entity}
                                    enableDelete={enableDelete}
                                    enableEdit={enableEdit}
                                    enableView={enableView}
                                    handleSelectRecord={dataViewAPI.handleSelectRecord}
                                    handleDeselectRecord={dataViewAPI.handleDeselectRecord}
                                    handleDeleteClick={dataViewAPI.handleDeleteRecord}
                                    handleEditClick={dataViewAPI.handleEditClick}
                                    handleViewClick={dataViewAPI.handleViewClick}
                                    selected={dataViewAPI.selectedRecords.includes(index)}
                                    toggleSelectable={dataViewAPI.toggleSelectable}
                                />
                            })
                        }
                    </Group>
                }
                {!dataViewAPI.isLoading && !dataViewAPI.executeViewState.error && dataViewAPI.data.length > 0 && (dataViewAPI.recordsCount || 0) > dataViewAPI.displayedItemsCount &&
                    <Button size="xs" variant="light" onClick={handleLoadMore}>{labels?.loadMoreLabel}</Button>
                }
            </Stack>
        </section>
    )
});
