import { useState } from "react";
import { Entity, EntityDefinition } from "../../Entity";
import { OperationStatus, ValuesObject, toMicroMError } from "../../client";

export type useExecuteActionReturnType<TReturn extends EntityDefinition | ValuesObject> = {
    status: OperationStatus<TReturn>
    execute: (cancellation: AbortSignal, values?: ValuesObject) => Promise<void>
}

export function useExecuteServerAction<T extends EntityDefinition, TReturn extends EntityDefinition | ValuesObject>(entity: Entity<T>, actionName: string): useExecuteActionReturnType<TReturn> {
    const [status, setStatus] = useState<OperationStatus<TReturn>>({ loading: true });

    const execute = async function exec(cancellation: AbortSignal, values?: ValuesObject) {
        setStatus({ loading: true });

        try {
            const action = entity.def.serverActions[actionName];
            if (!action.valuesMapper) throw new Error('valuesMapper missing.');
            const data = await entity.API.executeServerAction<ValuesObject>(action, values, cancellation);

            const new_values = action.valuesMapper(data);
            setStatus({ data: new_values as TReturn });
        }
        catch (e: any) {
            if (e.name !== 'AbortError') {
                setStatus({ error: toMicroMError(e) });
            }
        }
    };

    return { status: status, execute: execute };
}



