import { UseFormReturnType } from "@mantine/form";
import { Value, ValuesObject } from "../../client";
import { EntityColumn } from "../../Entity";
import { useEffect, useState } from "react";

export interface UseCompleteFieldFromOtherProps {
    form: UseFormReturnType<ValuesObject>,
    originalColumn: EntityColumn<Value>,
    targetColumn: EntityColumn<Value>,
}

export function useCompleteFieldFromOther({ form, originalColumn, targetColumn }: UseCompleteFieldFromOtherProps) {

    const [previousValue, setPreviousValue] = useState<Value>(form.values[originalColumn.name]);

    useEffect(() => {
        if (originalColumn.type !== targetColumn.type) {
            console.error(`useCompleteFieldFromOther: originalColumn.type !== targetColumn.type`);
            return;
        }
        if (form.values[originalColumn.name] !== previousValue) {
            setPreviousValue(form.values[originalColumn.name]);
            form.setFieldValue(targetColumn.name, form.values[originalColumn.name]);
        }
    }, [form.values[originalColumn.name], previousValue])

}