import { AddressMappingRule } from "./types";

export const ARMappingRules: AddressMappingRule = {
    conditions: { country: { short_name: 'AR' }, locality: { short_name: 'CABA' } },
    mapping: {
        city: [{ administrative_area_level_1: 'long_name' }],
        cityCode: [{ administrative_area_level_1: 'short_name' }],
        department: [{ sublocality_level_1: 'long_name' }],
        departmentCode: [{ sublocality_level_1: 'short_name' }],
    }
}

export const UYMappingRules: AddressMappingRule = {
    conditions: { country: { short_name: 'UY' } },
    mapping: {
        city: [{ locality: 'long_name' }],
        cityCode: [{ locality: 'short_name' }],
        department: [{ neighborhood: 'long_name', locality: 'long_name' }],
        departmentCode: [{ neighborhood: 'short_name', locality: 'short_name' }],
    }
}

export const USMappingRules: AddressMappingRule = {
    conditions: { country: { short_name: 'US' } },
    mapping: {
        city: [{ locality: 'long_name' }],
        cityCode: [{ locality: 'short_name' }],
        apartment: [{ subpremise: 'long_name'}],
    }
}

