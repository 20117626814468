import { Button, ButtonProps } from "@mantine/core";
import { Entity, EntityClientAction } from "../../Entity";
import { ValuesObject } from "../../client";
import { useModal } from "../Core";

export interface EntityActionButtonProps extends Omit<ButtonProps, 'leftIcon'> {
    entity: Entity<any>,
    action: EntityClientAction,
    selectedKeys?: ValuesObject[]
}

//const TestAction: EntityClientAction = {
//    title: 'test',
//    label: 'test',
//    name: 'test',
//    onClick: async ({ entity, modal, modalProps, selectedKeys }) => {
//        const editEntity = Entity.clone(entity);
//        setValues(editEntity.def.columns, selectedKeys?.[0], null, true);

//        await modal?.open({
//            content: <></>,
//            modalProps: modalProps ?? {}
//        })
//    }
//}

export function EntityActionButton(props: EntityActionButtonProps) {
    const modal = useModal();
    const { entity, action, selectedKeys } = props;


    return (
        <Button leftIcon={action.icon} onClick={async () => {
            await action.onClick({ entity, modal, selectedKeys });
        }}>{action.label}</Button>
    )
}